import { useState, useEffect, useCallback } from "react";
import { BigNumber, ethers } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { DEFAULT_CHAIN_ID } from "../../../constants/network";
import {
  getContractInstance,
  SmartContractMethod,
} from "../../../services/web3";

import STAKING_POOL_ABI from "../../../abi/StakingPool.json";
import { useDispatch } from "react-redux";
import { alertFailure } from "../../../store/actions/alert";

const useDetailListStakingPool = (poolsList: Array<any> | null | undefined) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [linearPools, setLinearPools] = useState({});
  const connector = useTypedSelector((state: any) => state.connector).data;
  const { account } = useWeb3React();
  const dispatch = useDispatch();

  const fetchDetailList = useCallback(async () => {
    try {
      if (!poolsList || !poolsList.length) {
        return;
      }

      setLoading(true);

      const linearPoolsList = poolsList.filter(
        (pool) => pool.staking_type !== "alloc"
      );

      const linearPoolsData = await Promise.all(
        linearPoolsList.map(async (pool) => {
          if (
            !pool.pool_address ||
            !ethers.utils.isAddress(pool.pool_address)
          ) {
            return null;
          }

          const contract = getContractInstance(
            STAKING_POOL_ABI,
            pool.pool_address,
            connector,
            DEFAULT_CHAIN_ID,
            SmartContractMethod.Read
          );

          if (!contract) {
            return null;
          }

          const [
            linearAcceptedToken,
            linearData,
            linearUserInfo,
            linearPendingReward,
            linearPendingWithdrawal,
          ] = await Promise.all([
            contract.methods.linearAcceptedToken().call(),
            contract.methods
              .linearPoolInfo(BigNumber.from(pool.pool_id))
              .call(),
            account
              ? contract.methods
                  .linearStakingData(BigNumber.from(pool.pool_id), account)
                  .call()
              : null,
            account
              ? contract.methods
                  .linearPendingReward(BigNumber.from(pool.pool_id), account)
                  .call()
              : "0",
            account
              ? contract.methods
                  .linearPendingWithdrawals(
                    BigNumber.from(pool.pool_id),
                    account
                  )
                  .call()
              : null,
          ]);

          return {
            ...pool,
            acceptedToken: linearAcceptedToken,
            cap: linearData.cap,
            totalStaked: linearData.totalStaked,
            minInvestment: linearData.minInvestment,
            maxInvestment: linearData.maxInvestment,
            APR: linearData.APR,
            lockDuration: linearData.lockDuration,
            delayDuration: linearData.delayDuration,
            startJoinTime: linearData.startJoinTime,
            endJoinTime: linearData.endJoinTime,
            stakingAmount: linearUserInfo?.balance || "0",
            stakingJoinedTime: linearUserInfo?.joinTime || "0",
            pendingReward: linearPendingReward,
            pendingWithdrawal: {
              amount: linearPendingWithdrawal?.amount || "0",
              applicableAt: linearPendingWithdrawal?.applicableAt || "0",
            },
          };
        })
      );

      let poolObj = {};
      linearPoolsData?.forEach((pool, index) => {
        poolObj = {...poolObj, [index + 1]: pool}
      })

      if (poolObj) {
        setLinearPools(poolObj);
      }
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      dispatch(alertFailure(err.message || "Load staking pool failed"));
    }
  }, [poolsList, account, connector]);

  useEffect(() => {
    fetchDetailList();
  }, [fetchDetailList]);

  return {
    loading,
    fetchDetailList,
    linearPools,
  };
};

export default useDetailListStakingPool;
